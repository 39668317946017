<template>
  <ticket filtered v-if="isLoaded">
    <template v-slot="slotProps">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" class="d-flex justify-center">
          <h1 class="ma-0">PERFORMANCES DU SITE</h1>
        </v-col>
        <v-col cols="11" class="pb-0 pt-0" v-if="displayedTicketChart">
          <tickets-chart
            :loaded="slotProps.exposedData.loaded"
            :tickets="slotProps.exposedData.tickets"
            title="Performance technique"
          ></tickets-chart>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="11" class="px-0 px-md-0">
          <env-performance
            :entity-id="Number.parseInt($route.params.id)"
          ></env-performance>
        </v-col>
      </v-row>
    </template>
  </ticket>
</template>

<script>
import Ticket from "../../components/technique/Ticket";
import TicketsChart from "../../components/technique/TicketsChart";
import EnvPerformance from "../../components/ecran/EnvPerformance";
import { mapGetters } from "vuex";

export default {
  name: "Performance",
  components: { Ticket, TicketsChart, EnvPerformance },
  computed: {
    ...mapGetters(["sessionIsInit", "getEcranModule"]),
    isLoaded() {
      return this.sessionIsInit;
    },
    displayedTicketChart() {
      if (!this.getEcranModule) return true;
      return this.getEcranModule.module2 != 0;
    }
  }
};
</script>

<style scoped></style>
